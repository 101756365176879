import {DeleteOutlined} from "@mui/icons-material";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Tooltip
} from "@mui/material";
import {useSnackbar} from "notistack";
import React, {useEffect, useState} from "react";
import {callApi, saveApi} from "../App";

export default function SecurityGroupListDialog(props){
    const { enqueueSnackbar } = useSnackbar();
    const [list, setList] = useState([])
    const instance = props.securityGroup.instance;
    const [exists, setExists] = useState(instance.securityGroup.map(sg => sg.id))
    const [existList,setExistList] = useState(instance.securityGroup);

    useEffect(() => {
        if( list.length > 0 ) return ;
        
        callApi(`/ec2/securityGroup/list?profile=${instance.profile}&region=${instance.region}`, setList
            , null, (msg) => enqueueSnackbar(msg.msg, {variant: msg.severity}))
    }, [instance.profile])

    useEffect(() => {
        setExists(existList.map(sg => sg.id))
    }, [existList])

    return (
        <Dialog open={true}>
            <DialogTitle>{instance.name} 보안 그룹 목록</DialogTitle>

            <DialogContent>
                <Autocomplete renderInput={(params) => (
                    <TextField
                        {...params}
                        label="보안그룹 이름"
                    />
                )} options={list.filter(sg => !exists.includes(sg.id))} getOptionLabel={(sg) => sg.name} onChange={handleOptionSelect}
                />

                <Table>
                    <TableBody>
                        {existList.map(sg => {
                            return (
                                <TableRow>
                                    <TableCell>{sg.id}</TableCell>
                                    <TableCell>{sg.name}</TableCell>
                                    <TableCell>{sg.desc}</TableCell>
                                    <TableCell>
                                        <Tooltip title="보안 그룹 규칙을 삭제합니다." placement="top">
                                            <DeleteOutlined onClick={() => deleteSG(sg)}/>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>)
                        })}
                    </TableBody>
                </Table>
            </DialogContent>

            <DialogActions>
                <Button variant="primary" onClick={saveSecurityGroup} title={`입력한 값으로 정보를 변경 합니다.`}>
                    저장
                </Button>
                <Button variant="secondary" onClick={checkClose} title="팝업 창을 닫습니다.">
                    닫기
                </Button>
            </DialogActions>
        </Dialog>
    );

    function handleOptionSelect (event, selectedSG) {
        if (selectedSG) {
            console.debug(`Selected option: ${selectedSG.id}`);
            if( exists.includes(selectedSG.id) ){
                enqueueSnackbar("이미 등록된 보안 그룹 입니다.", {variant: "warning"})
            }else{ // 보안 그룹 목록에 추가 한다.
                // props.securityGroup.instance.securityGroup.push(selectedSG)
                setExistList([...existList,selectedSG])
                //props.setInstance(props.securityGroup.instance);
                enqueueSnackbar("추가 되었습니다. 저장 해야 반영됩니다.", {variant: "success"})
            }
        }
    }

    function deleteSG(selectedSG){
        if( exists.includes(selectedSG.id) ){
            //props.securityGroup.instance.securityGroup = props.securityGroup.instance.securityGroup.filter(sg => sg.id !== selectedSG.id)
            setExistList(existList.filter(sg => sg.id !== selectedSG.id))
            //props.setInstance(props.instance);
            enqueueSnackbar(`${selectedSG.name} 보안 그룹이 삭제 되었습니다.`, {variant: "success"})
        } else {
            enqueueSnackbar("해당 보안 그룹이 없습니다.", {variant: "warning"})
        }
    }

    function saveSecurityGroup(){
        const instance = props.securityGroup.instance;
        const noChangeLength = props.securityGroup.instance.securityGroup.filter(sg => exists.includes(sg.id)).length
        if( noChangeLength === props.securityGroup.instance.securityGroup.length && exists.length === noChangeLength ){
            enqueueSnackbar("저장할 보안 그룹이 없습니다.", {variant: "warning"})
        }else{
            const data = existList.map(({id,name}) => ({id, name}))
            const uri = `/ec2/${instance.id}/securityGroups/replace?profile=${instance.profile}&region=${instance.region}`
            saveApi(uri,"PUT", data , (result) => {
                console.debug(`save security group result : ${result}`)
                props.securityGroup.instance.securityGroup = existList
                props.setInstance(props.securityGroup.instance);
                if( result ){
                    enqueueSnackbar("EC2의 보안 그룹이 변경되었습니다.", {variant: "success"})
                    props.setSecurityGroup({})
                }else
                    enqueueSnackbar("보안 그룹 목록을 EC2 인스턴스에 등록에 실패 했습니다.", {variant: "warning"})
            }, (msg) => {enqueueSnackbar(msg.msg, { variant: msg.severity})}) 
        
        }
    }

    function checkClose(){
        const noChangeLength = props.securityGroup.instance.securityGroup.filter(sg => exists.includes(sg.id)).length
        if( noChangeLength === props.securityGroup.instance.securityGroup.length && exists.length === noChangeLength  ){
            props.setSecurityGroup({})
        }else{
            enqueueSnackbar("변경 사항이 저장되지 않았습니다.", {variant: "warning"})
            if( window.confirm("변경 사항이 저장되지 않았습니다. 그래도 닫으시겠습니까?") ){
                props.setSecurityGroup({})
            }
        }
    }
}