import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {useSnackbar} from "notistack";
import React, {useContext} from "react";
import {saveApi, UserContext} from "../App";
import {MainContext} from "../Main";
import {fetchServerInfo} from "./Ec2";

const fullWidthKey = ["schedule", "endpoint", "health-check", "description", "wiki"]
const shortWidthKey = ["Name", "group", "gitlab", "backup-policy", "mm-channel", "mm-extra-info", "manager"]
export default function Ec2TagEditDialog({instance, setInstance}) {
    const { enqueueSnackbar } = useSnackbar();
    const context = useContext(MainContext);
    const user = useContext(UserContext);
    let tags = {}

    return (
        <Dialog className="flex-" open={true} onClose={() => setInstance(null)}>
            <DialogTitle>서버 정보 변경</DialogTitle>

            <DialogContent>
                {shortWidthKey.map((k) =>
                    <TextField
                        key={k}  // Add key prop for React list rendering
                        id={k}
                        label={k}
                        defaultValue={instance && instance.tags?.[k] || ""}
                        variant="standard"
                        onChange={(e) => handleInputChange(k, e)}
                    />
                )}
                {fullWidthKey.map((k) =>
                <TextField
                    key={k}  // Add key prop for React list rendering
                    id={k}
                    label={k}
                    defaultValue={instance && instance.tags?.[k] || ""}
                    fullWidth
                    variant="standard"
                    onChange={(e) => handleInputChange(k, e)}
                />)}
            </DialogContent>
            <DialogActions>
                <Button variant="primary" onClick={changeValue} title={`입력한 값으로 정보를 변경 합니다.`}>
                    정보 변경
                </Button>
                <Button variant="secondary" onClick={() => setInstance(null)} title="팝업 창을 닫습니다.">
                    닫기
                </Button>
            </DialogActions>
        </Dialog>
    );

    function handleInputChange(key, e) {
        tags[key] = e.target.value
    }

    function changeValue() {
        console.debug(tags)
        if(  Object.entries(tags).length === 0 ) return;
        const target = [{key: "last-access", value: `${user.name}(${Object.keys(tags).join(", ")})`}];
        let index=0;
        Object.entries(tags)
            .filter(([k,v]) => instance.tags[k] !== v)
            .map(([k,v]) => target[index++] = {key: k, value: v})
        
        console.debug( `tag change target : ${target}` )

        const uri = `/ec2/${instance.id}/changeTags?profile=${instance.profile}&gitlab=${instance.tags["gitlab"]}&region=${instance.region}`;

        saveApi(uri, 'PUT', target, () => {
            target.map(tag => instance.tags[tag.key] = tag.value );
            tags = {}
            if( target.filter(tag => tag.key === "group").length > 0 )
                context.setServers([])

            enqueueSnackbar(`서버 정보를 저장 했습니다. - ${target.map(tag => tag.key).join(", ")}`, { variant: 'success' });

            fetchServerInfo(instance, (msg) => {
                enqueueSnackbar(msg.msg, { variant: msg.severity });
            }, context)
        }, (msg) => {enqueueSnackbar(msg.msg, {variant: 'error'})})
    }
}