import React, {createContext, useContext, useEffect, useState} from 'react';
import {callApi, UserContext} from './App';
import Login from './Login';
import Ec2 from "./server/Ec2";
import Ec2Admin from "./server/Ec2Admin";
import {useSnackbar} from "notistack";
import ElasticIPs from "./server/ElasticIPs";
import Snapshots from "./server/Snapshots";
import Volumes from "./server/Volumes";
import Gitlab from "./Gitlab";

export const MainContext = createContext({
    servers: [],
});


export const PATH_EC2_V2 = "/ec2-v2";
export const PATH_EC2 = "/ec2";
export const PATH_SNAPSHOT = "/snapshot";
export const PATH_VOLUME = "/volume";
export const PATH_EIP = "/eip";
export const PATH_GITLAB = "/gitlab";

export default function Main() {
    const [path, setPath] = useState(window.location.pathname)
    const { enqueueSnackbar } = useSnackbar();
    const user = useContext(UserContext);
    const [servers, setServers] = useState([])
    const [filter, setFilter] = useState([])
    const [keyword, setKeyword] = useState("")
    const [auth, setAuth] = useState(false)

    useEffect(() => {
        if (user.email === undefined || user.email === '') return
        if (servers.length === 0) callApi('/ec2/instances', (result) => {
            console.debug("result : ", result)
            if (result.length > 0) setServers(result)
            const groupList = result.reduce((acc, curr) => {
                const groups = curr.group.split(',').map(v => v.trim());
                acc.push(...groups);
                return acc;
            }, []);

            if (result.length > 0) setFilter([...new Set(groupList)])
            if ( ! result.some((server) => server.accessLevel > 10) ) 
                enqueueSnackbar("권한 설정을 위해 Gitlab 조회에 다소 시간이 걸릴 수 있습니다. 기다려주세요....", {variant: 'info'})
        }, null, (msg) => { alert(msg.msg)})
        else if( Object.values(user.projAuthMap).length > 0 && auth === false) {
            console.debug("user.projAuthMap : ", user.projAuthMap)
            servers.forEach((server) => {
                server.accessLevel = user.projAuthMap[server.gitlab]
            })
            setServers([...servers])
            enqueueSnackbar("권한 설정이 변경되었습니다. 서버 필터에서 'Authorized'를 선택하시면 권한이 부여된 서버만 조회됩니다.", {variant: 'success'})
            setAuth(true)   
        }
    }, [user.email,user.projAuthMap, servers])

    const context = { path: path, setPath: setPath, filter: filter, servers: servers, setServers: setServers, keyword: keyword, setKeyword: setKeyword };


    console.debug("pathname : ", path, user.email)

    //if (path === "/" && user.email && user.isAdmin) setPath(PATH_EC2_V2)

    return (<MainContext.Provider value={context}>
        
            {/* {user.email && user.isAdmin && <Ec2Admin/>} */}
            {/* {user.email && !user.isAdmin && <Ec2/>} */}
            {user.email && path === PATH_EC2_V2 && <Ec2Admin />}
            {user.email && path === PATH_EIP && <ElasticIPs />}
            {user.email && path === PATH_SNAPSHOT && <Snapshots />}
            {user.email && path === PATH_VOLUME && <Volumes />}
            {user.email && path === PATH_GITLAB && <Gitlab />}
            {user.email && (path === PATH_EC2 || path === "/") && <Ec2 />}

            {user.email === "" && <Login />}
    </MainContext.Provider>);
}