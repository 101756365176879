import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Box, Breadcrumbs, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Link, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState, useContext } from "react";
import { callApi, UserContext } from "./App";
import ResourceToolbar from "./server/ResourceToolbar";
import RefreshIcon from '@mui/icons-material/Refresh';  // Add this import

export default function Gitlab() {
    const userContext = useContext(UserContext)
    const [resourceName, setResourceName] = useState("");
    const [gitProjects, setGitProjects] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [currentPath, setCurrentPath] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [selectedBranches, setSelectedBranches] = useState({});
    const [commonBranch, setCommonBranch] = useState('');
    const [pipelineDialog, setPipelineDialog] = useState({
        open: false,
        projectUrl: '',
        branch: '',
        projectName: ''
    });
    const [imageVersion, setImageVersion] = useState({});  // Add this state

    const fetchGitProjects = () => {
        callApi('/gitlab/projects', (result) => {
            console.debug("result : ", result)
            if (result.length > 0) {
                const sortedProjects = result.sort((a, b) => 
                    a.path_with_namespace.localeCompare(b.path_with_namespace)
                );
                setGitProjects(sortedProjects);
            }else{
                enqueueSnackbar( "Git Project 데이터가 없습니다. 잠시 후에 다시 시도해 주세요", { variant: "info", autoHideDuration: 2000 });
            }
        }, null, (msg) => { enqueueSnackbar(msg.msg, { variant: msg.severity }) });
    };

    useEffect(() => {
        if (gitProjects.length === 0) {
            fetchGitProjects();
        }
    }, []);  // Add empty dependency array to run only once

    // Remove the second useEffect that was fetching branches

    const reload = () => {
        // Add your reload logic here
        refreshAllPipelineStatuses();
        console.log("Reloading gitlab resources...");
    };

    const getProjectsAtLevel = (projects) => {
        if (!projects.length) return [];
        
        if (currentPath.length === 3) {
            // At max depth, show actual projects
            return projects.filter(p => {
                const projectPath = p.path_with_namespace;
                const pathParts = projectPath.split('/');
                return currentPath.every((segment, index) => pathParts[index] === segment) && (resourceName === "" || p.name.toLowerCase().includes(resourceName.toLowerCase()));
            });
        }

        // Get unique segments at current level
        const segments = new Set();
        projects.forEach(project => {
            const pathParts = project.path_with_namespace.split('/');
            if (pathParts.length === currentPath.length + 1) {
                if(currentPath.every((segment, index) => pathParts[index] === segment) &&
                    (resourceName === "" || project.name.toLowerCase().includes(resourceName.toLowerCase())) )
                    segments.add( project );
            }
            else if (pathParts.length > currentPath.length ) {
                // Only include if all current path segments match
                const isMatch = currentPath.every((segment, index) => pathParts[index] === segment);
                if (isMatch) {
                    segments.add(pathParts[currentPath.length]);
                }
            // }else {

            }
        });

        return Array.from(segments);
    };

    const getCommonBranches = (projects) => {
        if (!projects.length || typeof projects[0] !== 'object') return [];
        
        // Create a map to count branch occurrences
        const branchCounts = new Map();
        
        // Count how many projects have each branch
        projects.forEach(project => {
            project.branches?.forEach(branch => {
                const count = branchCounts.get(branch.name) || 0;
                branchCounts.set(branch.name, count + 1);
            });
        });
        
        // Filter branches that appear in 5 or more projects
        const commonBranches = Array.from(branchCounts.entries())
            .filter(([_, count]) => count >= 2)
            .map(([branchName]) => branchName)
            .sort();

        return commonBranches;
    };

    const getProjectsCount = (segment) => {
        const currentFullPath = [...currentPath, segment];
        return gitProjects.filter(p => {
            const pathParts = p.path_with_namespace.split('/');
            return currentFullPath.every((segment, index) => pathParts[index] === segment);
        }).length;
    };

    const handlePathClick = (segment) => {
        if (currentPath.length < 3) {
            setCurrentPath(prev => [...prev, segment]);
        }
    };

    const handleBreadcrumbClick = (index) => {
        setCurrentPath(prev => prev.slice(0, index));
    };

    const handleBranchChange = (projectId, branchName) => {
        setSelectedBranches(prev => ({
            ...prev,
            [projectId]: branchName
        }));
    };

    const handleCommonBranchChange = (branchName) => {
        setCommonBranch(branchName);
        // Update all visible projects to use this branch
        const visibleProjects = getProjectsAtLevel(gitProjects);
        const newSelectedBranches = { ...selectedBranches };
        visibleProjects.forEach(project => {
            if (typeof project === 'object') {
                newSelectedBranches[project.id] = branchName;
            }
        });
        setSelectedBranches(newSelectedBranches);
    };

    // Add this function
    const refreshPipelineStatus = (projectId) => {
        setImageVersion(prev => ({
            ...prev,
            [projectId]: (prev[projectId] || 0) + 1
        }));
    };

    // Add these functions
    const refreshAllPipelineStatuses = () => {
        const visibleProjects = getProjectsAtLevel(gitProjects);
        visibleProjects.forEach(project => {
            if (typeof project === 'object') {
                refreshPipelineStatus(project.id);
            }
        });
    };

    // Add new useEffect for auto-refresh
    useEffect(() => {
        const visibleProjects = getProjectsAtLevel(gitProjects);
        // Only start interval if there are visible projects
        if (visibleProjects.some(project => typeof project === 'object')) {
            const intervalId = setInterval(refreshAllPipelineStatuses, 5 * 60 * 1000); // 5 minutes
            
            // Refresh immediately on mount or when visible projects change
            refreshAllPipelineStatuses();
            
            // Cleanup interval on unmount or when visible projects change
            return () => clearInterval(intervalId);
        }
    }, [gitProjects, currentPath, selectedBranches]); // Dependencies that affect visible projects

    const hasProject = () => {
        const visibleProjects = getProjectsAtLevel(gitProjects);
        return visibleProjects.some(project => typeof project === 'object');
    }
    return (<>
        <ResourceToolbar reload={reload} resourceName={resourceName} searchResource={setResourceName} />
        <div className="row py-1"></div>
        <div className="row px-4 py-5">
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Button onClick={() => {
                        setCurrentPath([]);
                        if (gitProjects.length === 0) {
                            fetchGitProjects();
                        }
                    }}>Root</Button>
                    {currentPath.map((path, index) => (
                        <Button 
                            key={path}
                            onClick={() => setCurrentPath(prev => prev.slice(0, index + 1))}
                        >
                            {path}
                        </Button>
                    ))}
                </Breadcrumbs>
                {hasProject() && (
                    <Box display="flex" alignItems="center" gap={1}>
                        <Typography variant="body2">Common Branch:</Typography>
                        <Select
                            size="small"
                            value={commonBranch}
                            onChange={(e) => handleCommonBranchChange(e.target.value)}
                            sx={{ minWidth: 120 }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {getCommonBranches(getProjectsAtLevel(gitProjects)).map(branchName => (
                                <MenuItem key={branchName} value={branchName}>
                                    {branchName}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                )}
            </Box>

            <Grid container spacing={2}>
                {getProjectsAtLevel(gitProjects).map((item) => {
                    const isProject = typeof item === 'object';
                    return (
                        <Grid item xs={12} sm={6} md={3} lg={2.4} key={isProject ? item.id : item}>
                            <Card 
                                sx={{ 
                                    cursor: isProject ? 'default' : 'pointer',
                                    '&:hover': {
                                        backgroundColor: isProject ? 'inherit' : '#f5f5f5'
                                    }
                                }}
                                onClick={() => !isProject && handlePathClick(item)}
                            >
                                <CardContent>
                                    {isProject ? (
                                        <>
                                            <Typography variant="h6">
                                                <Link href={item.web_url} target="_blank">
                                                    {item.name}
                                                </Link>
                                                {' '}
                                                <Box display="flex" alignItems="center" gap={1}>
                                                    <Box display="flex" alignItems="center">
                                                        <a target="_gitlab" href={`${item.web_url}/-/pipelines`}>
                                                            <img 
                                                                alt="pipeline status" 
                                                                src={`${item.web_url}/badges/${selectedBranches[item.id] || 'main'}/pipeline.svg?v=${imageVersion[item.id] || 0}`}
                                                                style={{ verticalAlign: 'middle' }}
                                                            />
                                                        </a>
                                                        <IconButton
                                                            size="small"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                refreshPipelineStatus(item.id);
                                                            }}
                                                            sx={{ 
                                                                padding: '2px',
                                                                ml: 0.5
                                                            }}
                                                        >
                                                            <RefreshIcon sx={{ fontSize: '0.875rem' }} />
                                                        </IconButton>
                                                    </Box>
                                                    <Select
                                                        size="small"
                                                        value={selectedBranches[item.id] || ''}
                                                        onChange={(e) => handleBranchChange(item.id, e.target.value)}
                                                        disabled={userContext.authority != "ADMIN" && (!userContext.projAuthMap[item.id] || userContext.projAuthMap[item.id] <= 20)}
                                                        sx={{ 
                                                            minWidth: 100,
                                                            '.MuiSelect-select': {
                                                                padding: '2px 14px',
                                                                fontSize: '0.75rem'
                                                            }
                                                        }}
                                                    >
                                                        {item.branches?.map(branch => (
                                                            <MenuItem 
                                                                key={branch.name} 
                                                                value={branch.name}
                                                                sx={{
                                                                    fontWeight: branch.default ? 'bold' : 'normal',
                                                                    color: branch.protected ? 'warning.main' : 'inherit',
                                                                    fontSize: '0.75rem',
                                                                    minHeight: '24px'
                                                                }}
                                                            >
                                                                {branch.name}
                                                                {branch.default && " (default)"}
                                                                {branch.protected && " 🔒"}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <IconButton 
                                                        size="small"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setPipelineDialog({
                                                                open: true,
                                                                projectUrl: item.web_url,
                                                                branch: selectedBranches[item.id] || 'main',
                                                                projectName: item.name
                                                            });
                                                        }}
                                                        sx={{ 
                                                            padding: '2px',
                                                            backgroundColor: 'action.selected',
                                                            '&:hover': {
                                                                backgroundColor: 'action.hover'
                                                            }
                                                        }}
                                                    >
                                                        <PlayArrowIcon sx={{ fontSize: '1rem' }} />
                                                    </IconButton>
                                                </Box>
                                            </Typography>
                                        </>
                                    ) : (
                                        <>
                                            <Typography variant="h6">{item}</Typography>
                                            <Typography color="textSecondary">
                                                {getProjectsCount(item)} projects
                                            </Typography>
                                        </>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
        </div>
        <Dialog 
            open={pipelineDialog.open} 
            onClose={() => setPipelineDialog(prev => ({ ...prev, open: false }))}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Run Pipeline - {pipelineDialog.projectName}</DialogTitle>
            <DialogContent>
                <Box sx={{ pt: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>
                        Branch: {pipelineDialog.branch}
                    </Typography>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        label="Variables (optional)"
                        placeholder="KEY=value&#10;ANOTHER_KEY=another_value"
                        variant="outlined"
                        size="small"
                        sx={{ mt: 2 }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={() => setPipelineDialog(prev => ({ ...prev, open: false }))}
                >
                    Cancel
                </Button>
                <Button 
                    variant="contained"
                    onClick={() => {
                        window.open(
                            `${pipelineDialog.projectUrl}/-/pipelines/new?ref=${pipelineDialog.branch}`,
                            '_blank'
                        );
                        setPipelineDialog(prev => ({ ...prev, open: false }));
                    }}
                >
                    Run Pipeline
                </Button>
            </DialogActions>
        </Dialog>
    </>);
}